<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic56D1E316E015512CBDF400BEC66A4DFE.jpg -->
    <img class="rounded" style="height: 689px;" src="@/assets/news-network/concept-squid-cannon-1.jpg">
    <p>(Panel 1)</p>

    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic2105C0E89C59D8E2DC24C57D46C0EACC.jpg -->
    <img class="rounded" style="height: 688px;" src="@/assets/news-network/concept-squid-cannon-2.jpg">
    <p>(Panel 2)</p>
    <p>
      <b>By Jim Stigall, Senior Concept Artist </b>
    </p>
    <p>
      We asked the NetDevil artists to tell us about their designs. Here's what they had to say:
    </p>
    <p>
      <b>What's happening in this picture?</b>
    </p>
    <p>
      <i>This storyboard describes the sequence of events surrounding the discovery of a pirate washing his land-locked squid and what happens when you try to sneak past them.</i>
    </p>
    <p>
      <b>What were the inspirations behind this image?</b>
    </p>
    <p>
      <i>The great link between seaworthy pirates and giant octopi.</i>
    </p>
    <p>
      <font size="1">Please note: All of these concept art creations are part of the creative process. There is no guarantee that they will be in the game.</font>
    </p>
  </div>
</template>
